<template>
	<h1>Privacy Policy</h1>
	<p>
		We respect and protect the privacy of our users and take appropriate steps
		to safeguard your personal information. This Privacy Policy (the "Policy")
		explains the types of personal information that we receive and collect when
		you use the services we offer ("Services"), including but not limited to the
		Arthur website, mobile and tablet applications.
	</p>
	<p>
		This privacy policy was last updated on July 25, 2012, and is subject to
		revision from time to time. If we make any material changes to our policies,
		we will place a prominent notice on our website or application. If the
		change materially affects registered users, we will send a notice to you by
		email or push notification.
	</p>

	<h3>What Data We Collect</h3>
	<p>
		When you create a profile on the Arthur site, we may collect personally
		identifiable information that you provide to us, such as your name, address,
		phone number, email address, photo or nickname. We will ask you to create a
		password for your Arthur account, and you may provide us with additional
		email addresses that we will link with your account. With your permission,
		we may also access other profile and personal information from third-party
		services such as Facebook, Twitter and Google. Such information may include
		passwords, usernames, profile pictures, and other such data that you have
		posted on third-party sites and services.
	</p>
	<p>
		The contents of any material that you post directly to the Services,
		including any text, images, photos, videos and audio, are stored and
		maintained on our servers in order to publish these items and provide the
		Services. Your submitted content will be associated with your account. Our
		systems may also associate personal information with your usage activities
		in the course of providing Services to you (such as pages you view, the
		artwork you like and the feeds you follow).
	</p>
	<p>
		When you send email or other communication to Arthur, we may retain those
		communications in order to process your inquiries, respond to your requests
		and improve our Services.
	</p>

	<h3>How We Use Data</h3>
	<p>
		We maintain and process your personal information in order to provide your
		Arthur account and access. Our services are also used to provide a
		bookmarklet and mobile bookmarking application, which enable you to easily
		post and share content across Arthur. Third party login information may be
		used to allow you to access and share posts through the Service , but your
		passwords will not be saved or stored by Arthur unless it is required by
		that service to display the information on Arthur. The content, settings,
		profiles, preferences and user activity associated with your account will be
		stored and may be used to make recommendations to your tastes and
		preferences. We do this in order to provide our Services to you. We do not
		store passwords for third-party sites when you provide them as part of
		contact information retrieval.
	</p>
	<p>
		In addition to the above, Arthur may process personal information for such
		purposes as:
	</p>
	<ul class="bump-b">
		<li>
			Sending you information related to your Arthur account or other Services;
		</li>
		<li>
			Auditing, research and analysis in order to maintain, protect and improve
			our Services;
		</li>
		<li>Ensuring the technical functioning of our network; and</li>
		<li>Developing new services.</li>
	</ul>

	<p>
		We use certain user information to better understand how visitors use our
		website. This information is commonly made available by web browsers and
		servers, and may include data about browser type, language preference,
		referring site, and the date and time of each visitor request.
	</p>
	<p>
		To operate the Services, we may make identifiable and anonymous information
		available to third parties in these limited circumstances: (1) with your
		express consent, (2) when we have a good faith belief it is required by law,
		(3) when we have a good faith belief it is necessary to protect our rights
		or property, or (4) to any successor or purchaser in a merger, acquisition,
		liquidation, dissolution or sale of assets. Your consent will not be
		required for disclosure in these cases, but we will attempt to notify you,
		to the extent permitted by law to do so.
	</p>
	<p>
		You are solely responsible for the content and privacy of what you share.
		The information we collect, as described above, may be used to facilitate
		the delivery to you of our Services and shared with trusted partners in
		order to provide you with relevant advertising, offers or services. You also
		control whether we share personal data with partners. Please
		<router-link :to="{ name: 'Contact' }">contact us</router-link> if you want
		to opt-out of this sharing:
	</p>

	<p>
		California residents are legally entitled (at no charge and no more than
		once annually) to request information about how we may have shared your
		information with others for direct marketing purposes. Please
		<router-link :to="{ name: 'Contact' }">contact us</router-link> for this
		information:
	</p>

	<p>
		Despite our policy against the sale or use of personal information without
		user consent, users should be aware that what they share on the Site may be
		discovered by third parties without the involvement of Arthur, including,
		without limitation, search engine crawlers, bots and the like. Users should
		always take that into consideration whenever they share something.
	</p>
	<p>
		We do not knowingly contact or collect personal information from individuals
		under the age of 18. If you believe we have inadvertently collected such
		information, please contact us so we can promptly obtain parental consent or
		remove the information.
	</p>

	<h3>Security</h3>
	<p>
		Information we collect may be stored or processed on computers located in
		any country where we do business. We take reasonable steps to secure your
		personally identifiable information against the unauthorized access,
		alteration, disclosure or destruction of data. We encrypt transmission of
		data on pages where you provide payment information. However, no security or
		encryption method can be guaranteed to protect information from hackers or
		human error.
	</p>

	<h3>Access to Data; Account Deletion</h3>
	<p>
		We remove personally identifiable information (such as your name, address,
		email or phone number) and other preferences associated with your account
		promptly after you delete your account. We may retain other data
		indefinitely. We take reasonable steps to ensure that the personal
		information we process is accurate, complete, and current, but we depend on
		our users to update or correct their personal information whenever
		necessary.
	</p>
	<p>
		You can sign into your account to see or delete any personally identifiable
		information we have stored, such as your name, address, email or phone
		number. You can also
		<router-link :to="{ name: 'Contact' }">contact us</router-link> to request
		to see or delete this information, or to bring any questions or concerns
		about this Privacy Policy to our attention. Please allow sufficient time for
		us to process your request.
	</p>
</template>

<script>
import { injectMetaData } from '@/use/MetaData'

export default {
	name: 'LegalPrivacy',
	setup() {
		injectMetaData({
			title: 'Arthur.io • Privacy Policy',
		})
	},
}
</script>
